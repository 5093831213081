import { observer } from "mobx-react"

const Checkbox = observer(({ label, secondLabel, checked, onChange }) => {

  const handleChange = e => {
    e.preventDefault()
    e.stopPropagation()
    const val = !checked
    onChange(val)
  }

  return (
    <label className="checkbox-container d-inline-block">
      <input onClick={(e) => handleChange(e)} type="checkbox" checked={checked} />
      <span onClick={(e) => handleChange(e)} className="checkmark"></span>
      <div onClick={(e) => handleChange(e)} className="checkbox-container_text d-inline-block">
        {label}
      </div>
      <span> </span>
      {
        secondLabel ? (
          <div className="checkbox-container_text d-inline-block">
            {secondLabel}
          </div>
        )
        :
        undefined
      }
    </label>
  )

})

export default Checkbox