import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Terms = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    document.title = `Privaatsus -ja Kasutustingimused | ISB Tellimiskeskus`
  }, [pathname])

  return (
    <div className="terms">
      <h1>Andmekaitsetingimused</h1>
      <section>
        <h2>Üldsätted</h2>
        <p>Oleme võtnud endale kohustuse kaitsta klientide ja kasutajate privaatsust. Käesolev privaatsuspoliitika ISB OÜ (edaspidi nimetatud ka „ISB“) poolt isikuandmete töötlemist.</p>
        <p>Isikuandmete töötlemisel lähtume isikuandmete kaitse üldmääruses isikuandmete kaitse seaduses, rahapesu ja terrorismi tõkestamise seaduses ja muudes asjakohastes õigusaktides, Andmekaitse Inspektsiooni juhendites ning käesolevas privaatsuspoliitikas sätestatud põhimõtetest.</p>
        <p>Käesolev privaatsuspoliitika kohaldub siis, kui Te:</p>
        <ul>
          <li>külastate veebilehte aadressil <a href="https://www.isb.ee">www.isb.ee</a>;</li>
          <li>külastate ISB sotsiaalmeedia kontot Facebookis;</li>
          <li>kandideerite tööle ISBi;</li>
          <li>sisestate enda andmed hinnapäringu saamiseks aadressil <a href="https://www.isb.ee">www.isb.ee</a> leitavasse kontaktivormi;</li>
          <li>sõlmite ISBiga lepingu teenuse osutamiseks;</li>
          <li>osutate teenuseid ISBile;</li>
          <li>võtate ISBiga ühendust kas e-posti või telefoni teel.</li>
        </ul>
        <p>Isikuandmete vastutav töötleja käesoleva privaatsuspoliitika tähenduses on ISB OÜ, registrikoodiga 12169135, asukohaga Radisti tee 5, Soodevahe küla, 75322
Rae vald, Harju maakond.
Meil on õigus käesolevat privaatsuspoliitikat igal ajal ühepoolselt muuta. Kõik
privaatsuspoliitika muudatused kajastatakse veebilehel <a href="https://www.isb.ee/privaatsuspoliitika/">www.isb.ee/privaatsuspoliitika</a>.</p>
      </section>

      <section>
        <h2>Isikuandmete kogumine ja kasutamine</h2>
        <p>Isikuandmete kogumine võib toimuda alljärgnevatel viisidel:</p>
        <ul>
          <li>Vabade töökohtade täitmiseks korraldatud konkursi puhul töötleme tööle
kandideerijate järgnevaid isikuandmeid: ees- ja perekonnanimi, kontaktandmed
nagu telefoni number, e-posti aadress, haridus, kvalifikatsioon, täiendõppe
läbimine jmt, keeleoskus, CV ja motivatsioonikiri, foto (juhul, kui kandideerija selle
esitab).</li>
          <li>Teenuse osutamiseks klientidele (sh hinnapäringu saatmiseks) töötleme järgnevaid
füüsilistest isikutest klientide või juriidilistest isikutest klientide esindajate
isikuandmeid: ees- ja perekonnanimi, isikukood, kontaktandmed nagu telefoni
number, e-posti aadress, elukoht.</li>
        <li>Kogume ka isikustamata andmeid küpsiste ehk cookie’de kaudu. Isikustamata
andmed on andmed, mida ei saa otseselt ühe kindla isikuga siduda (sugu, vanus,
keele-eelistus, asukoht). Võime koguda ka andmeid üldistatud klienditegevuste
kohta meie veebisaidil. Neid andmeid kasutatakse analüütiliste tööriistade jaoks ja
käsitletakse isikustamata andmetena.</li>
        </ul>
      </section>

      <section>
        <h2>Andmete edastamine</h2>
        <p>Edastame või avalikustame Teie isikuandmeid vaid siis, kui see on vajalik teenuse
osutamiseks või lepinguliste kohustuste täitmiseks. Lisaks edastame või avalikustame
isikuandmeid ka siis, kui selline edastamise või avalikustamise kohustus tuleneb seadusest.
Isikuandmete Vastuvõtjateks on näiteks raamatupidamisettevõtted, veebilehe haldaja,
Maksu- ja Tolliamet, töötaja poolt kasutatav pank, Sotsiaalkindlustusamet, Töötukassa,
vajadusel õiguskaitseasutused jmt.</p>
      </section>

      <section>
        <h2>Isikuandmete kaitse</h2>
        <p>Kõiki meie veebilehe külastamise ja kontaktivormi kaudu teatavaks saanud kliendi
          isikuandmeid käsitletakse kui konfidentsiaalset infot. Rakendame kõiki
          ettevaatusabinõusid (sh administratiivsed, tehnilised ja füüsilised meetmed) kliendi
          isikuandmete kaitsmiseks. Juurdepääs andmete muutmiseks ja töötlemiseks on ainult
          selleks volitatud isikutel. Tagame sellisel moel Teie isikuandmete töötlemise viisil, mis
          välistab säilitatavate, edastatavate või muul viisil töödeldavate andmete juhusliku või
          ebaseadusliku hävitamise, kaotsimineku, muutmise, nende loata avalikustamise või neile
          loata juurdepääsu.</p>
      </section>

      <section>
        <h2>Andmesubjekti õigused</h2>
        <p>Kui olete andnud nõusoleku isikuandmete töötlemiseks, on Teil õigus see nõusolek igal
          ajal tagasi võtta.
          Teil on õigus tutvuda enda kohta käivate isikuandmetega ning saada teavet isikuandmete
          töötlemise eesmärgi kohta. Teie kohta käivad isikuandmed edastame Teile struktureeritud,
          üldkasutatavas ja masinloetavas vormis. Selliselt edastatud isikuandmed on Teil õigus
          edastada muule vastutavale töötlejale ilma takistusteta.
          Kui töötlemisel olevad isikuandmed peaksid valed olema, on Teil õigus nõuda andmete
          parandamist. Lisaks on Teil õigus taotleda enda kohta kogutud isikuandmete kustutamist.
          Et saaksime Teie taotlusega tutvuda, saatke meile vastavasisuline e-kiri aadressile
          isb@isb.ee. Teie poolt saadetud teabenõudele vastame esimesel võimalusel, kuid
          hiljemalt 30 päeva jooksul. Keerulisematele ja mahukamatele teabenõuetele võime vastata
          ka 60 päeva jooksul alates vastava e-kirja saamisest.
          Kui leiate, et Teie isikuandmete töötlemine ei ole seaduslik, on Teil õigus esitada kaebus
          Andmekaitse Inspektsioonile (www.aki.ee, Tatari 39, 10134 Tallinn).</p>
      </section>

      <h1>Müügilepingu Üldtingimused</h1>
      <section>
          <h2>Üldsätted</h2>
          <p>1.1. Üldtingimused (edaspidi: „Üldtingimused“) on ISB OÜ (edaspidi:
„ISB või „Müüja“) poolt kehtestatud üldreeglid, mis kohalduvad
ISB poolt kirjalikult sõlmitud müügilepingutele.</p><p>
1.2. Üldtingimused on Müüja ja Ostja vahel sõlmitava müügilepingu (edaspidi:
„Leping“) lahutamatuks osaks. Leping koosneb käesolevate Üldtingimuste mõistes
eritingimustest, üldtingimustest ning muudest osadest (näiteks hinnapakkumine,
toodete hinnakiri), mis on nimetatud eritingimustes.</p><p>
1.3. Vastuolude ilmnemisel Üldtingimuste ja teiste Lepingu osade vahel, lähtuvad
lepingupooled eritingimustes ja hinnakirjas/hinnapakkumises/tellimiskeskuses
sätestatust.</p><p>
1.4. Üldtingimused kehtestavad Müüja ja Kliendi vaheliste õigussuhete põhialused,
õigused ja kohustused, suhtlemiskorra ning Lepingu muutmise ja lõpetamise
üldised tingimused. Eritingimustes on määratud kindlaks Müüja poolt müüdav asi
(edaspidi „Kaup“), hind, vajadusel ettemaksu suurus, üleandmise tingimused ja
tähtaeg, tarnekulude suurus, poolte kontaktandmed ja muud tingimused, mida
Pooled peavad oluliseks.</p><p>
1.5. Müüja võimaldab Kliendil enne Lepingu allkirjastamist tutvuda Üldtingimuste
sisuga ning muude Lepingu lisadega.</p><p>
1.6. Lepingu muudatused tuleb vormistada kirjalikult, kui Lepingust ei tulene teisiti.
Lepingu muudatused Kauba osas võivad kaasa tuua muudatusi Lepingu Hinnas
ja/või tähtajas.</p><p>
1.7. Pooled kinnitavad, et Lepingu sõlmimisega ei ole nad rikkunud ühtegi enda suhtes
kehtiva seaduse, põhikirja või muu normatiivakti sätet ega ühtki endale varem
sõlmitud lepinguga võetud kohustust.</p>
      </section>
      
      <section>
          <h2>Lepingu ese</h2>
          <p>Lepingu ese ehk Kaup on äratuntavalt määratletud eritingimustes.</p>
          <p>2.1. Lepingu ese ehk Kaup on äratuntavalt määratletud eritingimustes.</p><p>
2.2. Kõik Lepingu eritingimustes nimetamata objektid lepitakse eraldi kokku poolte
vahel kirjalikult koos tasustamise ja üleandmise tähtaegadega.</p>
      </section>
      
      <section>
          <h2>Kauba üleandmine ja vastuvõtmine</h2>
          <p>3.1. Lepingu eseme üleandmise viis ja tähtaeg on määratletud Lepingu eritingimustes.
Koos Kaubaga annab Müüja Kliendile üle kõik Kauba juurde kuuluvad</p><p>
dokumendid.
3.2. Kliendile Kauba üleandmisega kaasnevate transpordikulude suurus ja nende
hüvitamine määratakse Lepingu eritingimustega.</p><p>
3.3. Kauba juhusliku hävimise ja kahjustumise riisiko läheb Kliendile üle Kauba
üleandmisega.</p>
      </section>
      
      <section>
          <h2>Kaubal olevad puudused</h2>
          <p>4.1. Klient kohustub Kauba koheselt üle vaatama. Klient kontrollib Kauba
vastuvõtmisel selle kogust, omadusi, lepingutingimustele vastavust ja võimalike
puuduste olemasolu. Kohapeal ilmnenud puudustest kohustub Klient Müüjat
koheselt teavitama ning Pooled lepivad kokku puuduste kõrvaldamise protsessis.
Eeltoodud kokkulepe fikseeritakse kirjalikult.</p><p>
4.2. Kui puudused ilmnevad hiljem, kohustub Klient Müüjat Kaubal ilmnenud
puudustest teavitama kirjalikku taasesitamist võimaldavas vormis viivitamatult
puudustest teadasaamisest, kuid mitte hiljem kui seitsme (7) tööpäeva jooksul. Kui
Klient ei teavita Müüjat Kauba lepingutingimustele mittevastavusest ettenähtud aja
jooksul, kaotab Klient õiguse kasutada Müüja suhtes Lepingust ja seadusest
tulenevaid õiguskaitsevahendeid.</p>
      </section>
      
      <section>
          <h2>Müügihinna tasumine</h2>
          <p>5.1. Klient kohustub tasuma Müüjale kauba eest vastavalt Lepingus kokku lepitud
Hinnale ning tasustamise tingimustele.</p><p>
5.2. Lepinguga ettenähtud maksed tasub Klient Müüja pangaarvele.</p><p>
5.3. Juhul, kui Pooled on eritingimustes kokku leppinud ettemaksu tasumises, tasub
Klient ettemaksuna ühe (1) kalendripäeva jooksul arvates Lepingu sõlmimisest
kokkulepitud summa Müüja pangaarvele. Ülejäänud osa Kauba hinnast tasub
Klient kolme (3) kalendripäeva jooksul pärast seda, kui Müüja on Kliendile
teavitanud Kauba valmisolekust üleandmiseks.</p><p>
5.4. Kuni Lepingu hinna täieliku tasumiseni jäävad Lepingu alusel müüdavad asjad
Müüjale (omandireservatsioon). Lepingu alusel müüdavate asjade omand läheb
Kliendile üle pärast Lepingu hinna täies ulatuses tasumist.</p><p>
5.5. Müüjal on õigus muuta pärast Lepingu sõlmimist Kauba hinda juhul, kui Kauba
tootja muudab pärast Lepingu sõlmimist Müüjale kehtestatud hindu. Sellisel juhul
on Müüjal õigus muuta müügihinda proportsionaalselt Tootja muudetud
hindadega. Müüja on kohustatud Klienti müügihinna muutumisest informeerima
vastava kirjaliku teatega.</p><p>
5.6. Juhul, kui Üldtingimuste punktis 5.5 nimetatud hinnamuutuse tulemusena suureneb
Hind rohkem kui 20 %, võib Klient oma kirjaliku avaldusega Lepingust taganeda
ühe (1) nädala jooksul arvates Müüjalt hinnatõusu kohta kirjaliku teate saamisest.
Sellisel juhul Lepingust taganemisel kohustub Müüja tagastama Kliendile tasutud
ettemaksu seitsme (7) tööpäeva jooksul arvates Kliendi taganemisavalduse
saamisest.</p><p>
5.7. Juhul, kui Klient viivitab Lepingu alusel tasumisele kuuluvate summade
maksmisega, on Müüjal õigus nõuda viivise tasumist:</p><p>
5.7.1. füüsilisest isikust Kliendi puhul 0,06 % tähtajaks tasumata summalt iga viivitatud
päeva eest:</p><p>
5.7.2. juriidilisest isikust Kliendi puhul 0,1 % tähtajaks tasumata summalt iga viivitatud
päeva eest.</p><p>
5.8. Kui Klient ütleb lepingu üles või taganeb Lepingust Müüjast mittetuleneval
põhjusel, tuleb Kliendil tasuda leppetrahvina 25% tehingu summast, mille osas on
Müüjal tasaarvestusõigus ettemaksuga.</p>
      </section>
      
      <section>
          <h2>Lepingu kehtivus</h2>
          <p>6.1. Leping jõustub alates allkirjastamisest Poolte poolt ning kehtib kuni Lepingust
tulenevate kohustuste nõuetekohase täitmiseni.</p><p>
6.2. Müüjal on õigus Üldtingimusi ja Lepingu muid lisasid (sh hinnakirja) ühepoolselt
muuta, teavitades sellest Kliendile kirjalikku taasesitamist võimaldavas vormis ette
kolmkümmend (30) kalendripäeva. Muudatused jõustuvad, kui Klient ei teavita
Müüjat seitsme (7) kalendripäeva jooksul, et ta ei nõustu muudatustega. Juhul, kui
Klient ei nõustu muudatustega, loetakse Leping lõppenuks kolmekümne (30)
kalendripäeva möödumisel Müüja poolt Kliendile muudatustest teatamisest.</p><p>
6.3. Müüjal on õigus Leping erakorraliselt lõpetada muu hulgas juhul, kui Klient rikub
oluliselt Lepingust tulenevaid kohustusi, sealhulgas tasu maksmise kohustust.
Oluliseks rikkumiseks loetakse, kui Klient on viivituses tasumisega 30 päeva.</p><p>
6.4. Lepingu ennetähtaegseks lõpetamiseks edastab Lepingut lõpetada sooviv Pool
teisele Poolele vähemalt kirjalikku taasesitamist võimaldavas vormis teate, milles on
täpsustatud Lepingu lõpetamise faktiline ja õiguslik alus.</p><p>
6.5. Pool võib Lepingu üles öelda, kui kuulutatakse välja teise Poole pankrot.
6.6. Pooltel on õigus igal ajal Leping lõpetada kokkuleppel.</p><p>
6.7. Lepingu lõppemine ei mõjuta Poolte õigusi ja kohustusi, mis tekkisid enne Lepingu
lõppemist, sh Kliendi kohustust tasuda talle tarnitud kauba eest.</p>
      </section>
      
      <section>
          <h2>Muud tingimused</h2>
          <p>
          7.1. Pooled edastavad kogu olulise informatsiooni e-posti teel eritingimustes
kokkulepitud kontaktisiku e-posti aadressile. Teade loetakse kätte toimetatuks e-
posti kättesaamise kinnitamisel või kolme tööpäeva jooksul.</p><p>
7.2. Pooled vastutavad oma Lepingust tulenevate kohustuste mittetäitmise või
mittenõuetekohase täitmisega teisele Poolele tekitatud varalise kahju eest.</p><p>
7.3. Lepingust tulenevate kohustuste mittetäitmist või mittenõuetekohast täitmist ei
loeta Lepingu rikkumiseks, kui selle põhjuseks olid asjaolud, mille saabumist Pooled
Lepingu sõlmimisel ei näinud ette ega võinud ette näha ning mida Lepingu Pool ei
saanud mõjutada ja mõistlikkuse põhimõttest lähtudes ei saanud temalt oodata, et
ta Lepingu sõlmimise ajal selle asjaoluga arvestaks või seda väldiks või takistava
asjaolu või selle tagajärje ületaks (vääramatu jõud). Pool, kelle tegevus
lepingujärgsete kohustuste täitmisel on takistatud vääramatu jõu asjaolude tõttu, on
kohustatud sellest koheselt vähemalt kirjalikku taasesitamist võimaldavas vormis
teatama teisele Poolele.</p><p>
7.4. Vääramatu jõu esinemine peab olema tõendatud selle Poole poolt, kes soovib
viidata nimetatud asjaolule kui alusele, et vabaneda vastutusest kohustuste
mittetäitmise või mittevastava täitmise eest. Vääramatuks jõuks ei ole Poole
majandusliku olukorra muutus, hinnatõus, pankrot ega ka pankrotihoiatus või hagi
tagamine.</p><p>
7.5. Kõik käesolevast Lepingust tulenevad erimeelsused püütakse lahendada
läbirääkimiste teel. Juhul, kui läbirääkimised ei anna tulemusi, lahendatakse vaidlus
Tartu Maakohtus vastavalt Eesti Vabariigis kehtivale seadusandlusele.</p><p>
7.6. Leping allkirjastatakse digitaalselt või paberkandjal. Paberkandjal allkirjastatakse
Leping kahes eksemplaris, millest üks jääb Müüjale ja teine Kliendile.
          </p>
      </section>
    </div>
  )

}

export default Terms