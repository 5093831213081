import { MobXProviderContext, observer } from "mobx-react";
import SectionTitle from "../Partials/SectionTitle";
import { ReactComponent as Grid } from '../../svg/grid.svg';
import { ReactComponent as Trash } from '../../svg/trash.svg';
import { ReactComponent as Folder } from '../../svg/folder-main.svg';
import { ReactComponent as Close } from '../../svg/x-close.svg';
import { ReactComponent as Pencil } from '../../svg/pencil.svg';
import { ReactComponent as File } from '../../svg/file-check-light.svg';
import { ReactComponent as Truck } from '../../svg/truck.svg';
import { ReactComponent as CirclePlus } from '../../svg/plus-circle.svg';
import { ReactComponent as Chevron } from '../../svg/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Alert from "../Partials/Notice";
import Filters from "../Partials/Filters";
import CatalogRow from "../Partials/CatalogRow";
import { fetchDraftOrder } from "../../Store/api";
import Pagination from "../Partials/Pagination";
import LoadingScreen from "../Partials/LoadingScreen";
import Spinner from "../Partials/Spinner";
import Checkbox from "../Partials/Checkbox";
import { format } from "date-fns";

const CreateOrder = observer(() => {

  const { t } = useTranslation()

  const [orderName, setOrderName] = useState('')
  const [draftID, setDraftID] = useState(null)
  const [draftLoading, setDraftLoading] = useState(false)
  const [agreedTerms, setAgreedTerms] = useState(false)
  const [shippingMethod, setShippingMethod] = useState(null)
  const [shippingAddress, setShippingAddress] = useState({
    address1: {
      name: "address1",
      label: t("line1Label"),
      placeholder: t("line1Placeholder"),
      value: ''
    },
    zipCode: {
      name: "zipCode",
      label: t("zipCode"),
      placeholder: t("zipCode"),
      value: ''
    },
    county: {
      name: "county",
      label: t("state"),
      placeholder: t("state"),
      value: ''
    },
    country: {
      name: "country",
      label: t("country"),
      placeholder: t("country"),
      value: 'estonia',
    }
  })
  const [orderProducts, setOrderProducts] = useState([])
  const [note, setNote] = useState('')
  const [modal, setModal] = useState(false)
  const modalRef = useRef()
  const [modalProductRow, setModalProductRow] = useState(0)
  const [freeShipping, setFreeShipping] = useState(false)

  const { 
    content: { 
      productsLoading,
      productsFetched,
      setProductsFetched,
      initCatalogContent, 
      catalogContent,
      forceUpdateCatalog,
      paginateCatalogContent,
      getShippingMethods, 
      shippingMethods,
      shippingMethodsLoading,
      freeShippingThreshold
    }, 
  auth: { 
    showTaxRow,
    companyLoaded,
    company
  },
  order: {
    saveDraftOrder,
    deleteDraftOrder,
    createErplyOrder
  }
  } = useContext(MobXProviderContext)

  const navigate = useNavigate()

  const params = useParams()

  useEffect(() => {
    if(params.id && companyLoaded && !orderProducts.length && !draftLoading){
      getDraftOrder(params.id)
    }else{
      forceUpdateCatalog()
      syncShippingFields()
    }
  }, [companyLoaded])

  const syncShippingFields = () => {
    const { addressLines: { postalCode, street, state, country } } = company
    const copy = {...shippingAddress}
    copy.address1.value = street
    copy.county.value = state
    copy.zipCode.value = postalCode
    const lowerCountry = country.toLowerCase()
    const countryMap = {
      'latvia': 'latvia',
      'läti': 'latvia',
      'soome': 'finland',
      'finland': 'finland',
      'eesti': 'estonia',
      'estonia': 'estonia'
    }
    const targetCountry = countryMap[lowerCountry] ? countryMap[lowerCountry] : 'estonia'
    copy.country.value = targetCountry
    setShippingAddress(copy)
  }

  const getDraftOrder = async (id) => {
    try{
      if(draftLoading){
        return
      }
      setDraftLoading(true)
      const { order } = await fetchDraftOrder(id, company._id)
      setOrderName(order.name)
      setShippingMethod(shippingMethods.find(({ translationKey }) => translationKey === order.shippingMethod))
      if(!order.selfPickup && order.shippingAddress){
        const copy = {...shippingAddress}
        Object.keys(shippingAddress).forEach(key => {
          copy[key].value = order.shippingAddress[key]
        })
      }
      setOrderName(order.name)
      setNote(order.note)
      setOrderProducts(order.products)
      setDraftID(order._id)
      setDraftLoading(false)
      setProductsFetched(true)
    }catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    initCatalogContent()
    if(shippingMethods.length === 0){
      getShippingMethods()
    }
  }, [])

  const handleOrderNameChange = e => {
    const { value } = e.target
    setOrderName(value)
  }

  const deleteDraft = async () => {
    try{
      if(params.id){
        const res = await deleteDraftOrder(params.id)
        if(res){
          navigate('/order/drafts')
        }else{
          console.log('Delete failed')
          navigate('/order/drafts')
        }
      }else{
        navigate('/order/drafts')
      }
    }catch(err){
      console.log(err)
    }
  }

  const getShippingDetails = () => {
    const selfPickup = shippingMethod && shippingMethod.translationKey.includes('pickup')
    const shippingLines = {}
    if(!selfPickup && shippingMethod){
      Object.keys(shippingAddress).map(key => {
        shippingLines[key] = shippingAddress[key].value
      })
    }
    return {
      selfPickup,
      shippingLines
    }
  }

  const getEmptyName = () => {
    return `${t("order")} ${format(new Date(), 'dd.MM.yyyy hh:mm')}`
  }

  const saveDraft = async () => {
    try{
      const products = orderProducts
      const { selfPickup, shippingLines } = getShippingDetails()
      const name = orderName === '' ? getEmptyName() : orderName
      const res = await saveDraftOrder(products, name, shippingLines, shippingMethod ? shippingMethod.translationKey : '', selfPickup, draftID)
      if(res){
        navigate('/order/drafts')
      }
    }catch(err){
      console.log(err)
    }
  }

  const order = async () => {
    try{
      const products = orderProducts
      if(!shippingMethod){
        return
      }
      if(products.length === 0){
        return
      }
      const { selfPickup, shippingLines } = getShippingDetails()
      const name = orderName === '' ? getEmptyName() : orderName
      const id = await createErplyOrder(products, name, shippingLines, shippingMethod ? shippingMethod.translationKey : '', selfPickup, note, draftID)
      if(id){
        navigate(`/order/archive/${id}`)
      }
    }catch(err){
      console.log(err)
    }
  }

  const checkMethodValidity = () => {
    const orderWeight = calculateWeight()
    if(shippingMethod && shippingMethod.maxWeight < orderWeight && shippingMethod.maxWeight !== null){
      setShippingMethod(null)
    }
  }

  const handleProductSelect = (id, rowIndex) => {
    const product = catalogContent.content.find(({ productID }) => productID === id)
    const copy = [...orderProducts]
    copy.splice(rowIndex, 1, {...product, amount: 1})
    setOrderProducts(copy)
    closeModal()
    checkMethodValidity()
  }

  const addProduct = (product) => {
    const copy = [...orderProducts]
    copy.push({...product, amount: 1})
    setOrderProducts(copy)
    closeModal()
    checkMethodValidity()
  }

  const editProductAmount = (amount, id) => {
    const copy = [...orderProducts]
    const item = copy.find(({ productID }) => id === productID)
    item.amount = amount
    setOrderProducts(copy)
    checkMethodValidity()
  }

  const handleNoteChange = e => {
    const { value } = e.target
    setNote(value)
  }

  useEffect(() => {
    if(orderProducts && orderProducts.length){
      const sum = getOrderTotalSum(showTaxRow, false)
      const numeric = Number(sum)
      if(numeric >= freeShippingThreshold){
        setFreeShipping(true)
      }else{
        setFreeShipping(false)
      }
    }
  }, [orderProducts])

  const isEst = () => {
    const country = shippingAddress.country.value.toLowerCase()
    return country === 'estonia' || country === 'eesti'
  }

  const getOrderTotalSum = (tax = false, taxOnly = false, ignoreShipping = false) => {
    let orderSum = 0
    let orderTaxSum = 0
    orderProducts.forEach(({ productID, amount, priceListPrice, priceListPriceWithVat }) => {
      const totalPrice = Number(priceListPrice) * amount
      const totalPriceVAT = Number(priceListPriceWithVat) * amount
      if(!productID){
        orderSum += 0
      }else{
        orderSum += tax ? totalPriceVAT : totalPrice
        orderTaxSum += (totalPriceVAT - totalPrice)
      }
    })
    if(shippingMethod && shippingMethod.translationKey !== 'pickup' && !freeShipping && !ignoreShipping){
      const est = isEst()
      const cost = est ? shippingMethod.cost : shippingMethod.costEU
      orderTaxSum += (cost * 1.2 - cost)
      orderSum += (cost * (tax ? 1.2 : 1))
    }
    if(taxOnly){
      return orderTaxSum.toFixed(2)
    }
    return orderSum.toFixed(2)
  }

  const deleteRow = (idx) => {
    const copy = [...orderProducts]
    copy.splice(idx, 1)
    setOrderProducts(copy)
  }

  const openSearch = rowIndex => {
    setModal(true)
    setModalProductRow(rowIndex)
  }

  const closeModal = () => {
    setModal(false)
    setModalProductRow(0)
  }

  const handleShippingSelect = method => {
    setShippingMethod(method)
  }

  const handleModalClick = e => {
    if(e.target === modalRef.current){
      closeModal()
    }
  }

  const handleShippingChange = e => {
    const { name, value } = e.target
    const copy = {...shippingAddress}
    copy[name].value = value
    setShippingAddress(copy)
  }

  const calculateWeight = () => {
    let weight = 0
    orderProducts.forEach(product => {
      if(product && product.grossWeight){
        const { amount, grossWeight } = product
        const productWeight = Number(product.grossWeight) === 0 ? 1 : Number(product.grossWeight)
        weight += (productWeight * amount)
      }else{
        weight += 0
      }
    })
    return weight
  }

  const getOrderSummary = () => {
    return (
      <div className="summary-card card">
        <div className="card_body">
          <div className="row g-0 summary-card_row">
            <div className="col-6 summary-card_row-content">
              <label>{t('sum')}</label>
            </div>
            <div className="col-5 summary-card_row-content">
              <label>€{getOrderTotalSum(false, false, true)}</label>
            </div>
          </div>
          {
            shippingMethod ? (
              <div className="row g-0 summary-card_row">
                <div className="col-6 summary-card_row-content">
                  <label>{t('shippingPrice')}</label>
                </div>
                <div className="col-5 summary-card_row-content">
                  <label>€{freeShipping ? 0.00 : !isEst() ? shippingMethod.costEU.toFixed(2) : shippingMethod.cost.toFixed(2)}</label>
                </div>
              </div>
            )
            :
            undefined
          }
          {
            showTaxRow ? 
            (
            <div className="row g-0 summary-card_row">
              <div className="col-6 summary-card_row-content">
                <label>{t('VAT')}</label>
              </div>
              <div className="col-5 summary-card_row-content">
                <label>€{getOrderTotalSum(false, true)}</label>
              </div>
            </div>
            )
            :
            undefined
          }
        </div>
        <div className="card_footer">
          <div className="row g-0 summary-card_row">
            <div className="col-6 summary-card_row-content">
              <label className="text-100">{t('orderTotal')}</label>
            </div>
            <div className="col-5 summary-card_row-content">
              <label className="text-100">€{getOrderTotalSum(showTaxRow)}</label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handlePaginate = next => {
    paginateCatalogContent(next)
    document.querySelector('.modal-wrapper.search-modal').scroll(0, 0)
  }

  const handleTerms = (val) => {
    setAgreedTerms(val)
  }

  if((productsLoading || !productsFetched) || (draftLoading && orderProducts.length === 0) || (orderProducts.length === 0 && !draftLoading && draftID)){
    return (
      <LoadingScreen />
    )
  }

  return (
    <div className="order">
      <div className="order_header border-bottom">
        <div className="order_header-content">
          <div className="row g-0">
            <div className="col-md-6">
              <div className="form-group order_header-draft-form">
                <label>{t("orderName")}</label>
                <input className="form-control" placeholder={t('addName')} defaultValue={orderName} onChange={e => handleOrderNameChange(e)} />
              </div>
              <div className="order_header-buttons">
                <button onClick={() => saveDraft()} className="btn-none save-draft btn-0 prim-btn">
                  <Folder />
                  {t('saveOrder')}
                </button>
                <button onClick={() => deleteDraft()} className="btn-none save-draft btn-0 gray-btn">
                  <Trash />
                  {t('deleteOrder')}
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <Alert titleKey={"shippingTitle"} bodyKey={"shippingDetails"} className={"light"} />
            </div>
          </div>
        </div>
      </div>

      <div className="order_form">
        <div className="order_form-header">
          <SectionTitle titleKey={"products"} icon={<Grid />} />
        </div>
        <div className="order_form-table">
          {
            params.id ? 
            (
              <OrderForm 
                loading={draftLoading}
                showTaxRow={showTaxRow}
                openSearch={(idx) => openSearch(idx)} 
                orderProducts={orderProducts} 
                changeAmount={(value, id) => editProductAmount(value, id)} 
                addProduct={addProduct} 
                deleteRow={(idx) => deleteRow(idx)}
              />
            )
            :
            (
              <OrderForm 
                openSearch={(idx) => openSearch(idx)} 
                showTaxRow={showTaxRow}
                orderProducts={orderProducts} 
                changeAmount={(value, id) => editProductAmount(value, id)} 
                addProduct={addProduct} 
                deleteRow={(idx) => deleteRow(idx)}
              />
            )
          }
        </div>
      </div>

      <div className="order_details">
        <div className="row">
          <div className="col-lg-6">
            <div className="order_details-shipping">
              <SectionTitle titleKey={"shipping"} icon={<Truck />} />
              <div className="shipping-card card">
                <div className="card_header">
                  <div className="row g-0 shipping-card_rows">
                    <div className={`shipping-card_rows-header ${showTaxRow ? "col-7" : 'col-10'}`}>
                      <label>{t('shippingMethods')}</label>
                    </div>
                    <div className="shipping-card_rows-header col-2">
                      <label>{t('price')}</label>
                    </div>
                    {
                      showTaxRow ? 
                      (
                        <div className="shipping-card_rows-header col-3">
                          <label>{t('priceVAT')}</label>
                        </div>
                      )
                      :
                      undefined
                    }
                  </div>
                </div>
                <div className="card_body">
                  {
                    (shippingMethods.length === 0 || shippingMethodsLoading || draftLoading) ? (
                      <div style={{ height: '200px' }}>
                      </div>
                    )
                    :
                    (
                      shippingMethods.map(method => {
                        const isSelected = shippingMethod && method.translationKey === shippingMethod.translationKey
                        const orderWeight = calculateWeight()
                        const extra = {
                          pickup: "Radisti tee 5, Soodevahe, 75322, Harjumaa, Eesti",
                          pickup_tartu: "Männiku tee 1, Kandiküla, 61404, Tartu linn, Eesti",
                          shipping_delivery: t("courierDesc"),
                          omniva_parcel: t("parcelMachineDescription"),
                        }
                        if(method.maxWeight < orderWeight && method.maxWeight !== null){
                          return <div key={method.translationKey} />
                        }
                        return (
                          <div key={method.translationKey} className={`row g-0 position-relative shipping-card_row ${isSelected ? 'active' : 'default'}`}>
                            <div className={`shipping-card_row-content ${showTaxRow ? 'col-7' : 'col-10'}`}>
                              <input type="radio" className="shipping-card_row-content-radio" value={method.translationKey} checked={isSelected} onChange={() => handleShippingSelect(method)} />
                              <label onClick={() => handleShippingSelect(method)}>{t(method.translationKey)}</label>
                              {extra[method.translationKey] ? <span className="shipping-card_row-content-extra">{extra[method.translationKey]}</span> : undefined}
                            </div>
                            <div className="col-2 shipping-card_row-content">
                              <label>€{freeShipping ? 0.00 : !isEst() ? method.costEU.toFixed(2) : method.cost.toFixed(2)}</label>
                            </div>
                            {
                              showTaxRow ? (
                                <div className="col-3 shipping-card_row-content">
                                  <label>€{freeShipping ? 0.00 : !isEst() ? method.costEU.toFixed(2) : (method.cost * 1.2).toFixed(2)}</label>
                                </div>
                              )
                              :
                              undefined
                            }
                            {
                              isSelected && ["shipping_delivery", "omniva_parcel"].includes(method.translationKey) ? (
                                <>
                                  <div className="row g-0 shipping-card_row">
                                    <div className="shipping-card_row-content address-row col-lg-6 pe-lg-2 pe-0">
                                      <div className="form-group">
                                        <label>{shippingAddress.address1.label}</label>
                                        <input 
                                          className="form-control"
                                          type="address-line1" 
                                          name={shippingAddress.address1.name} 
                                          placeholder={shippingAddress.address1.placeholder} 
                                          value={shippingAddress.address1.value}
                                          onChange={e => handleShippingChange(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="shipping-card_row-content address-row col-lg-6">
                                      <div className="form-group">
                                        <label>{shippingAddress.zipCode.label}</label>
                                        <input 
                                          className="form-control"
                                          type="postal-code" 
                                          name={shippingAddress.zipCode.name} 
                                          placeholder={shippingAddress.zipCode.placeholder} 
                                          value={shippingAddress.zipCode.value}
                                          onChange={e => handleShippingChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-0 shipping-card_row">
                                    <div className="shipping-card_row-content address-row col-lg-6 pe-lg-2 pe-0">
                                      <div className="form-group">
                                        <label>{shippingAddress.county.label}</label>
                                        <input 
                                          className="form-control"
                                          type="address-level1" 
                                          name={shippingAddress.county.name} 
                                          placeholder={shippingAddress.county.placeholder} 
                                          value={shippingAddress.county.value}
                                          onChange={e => handleShippingChange(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="shipping-card_row-content address-row col-lg-6">
                                      <div className="form-group">
                                        <label>{shippingAddress.country.label}</label>
                                        <select name="country" className="form-control" value={shippingAddress.country.value} onChange={e => handleShippingChange(e)}>
                                          <option value={'estonia'}>{t("estonia")}</option>
                                          <option value={'latvia'}>{t("latvia")}</option>
                                          <option value={'finland'}>{t("finland")}</option>
                                        </select>
                                        <Chevron />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              :
                              undefined
                            }
                          </div>
                        )
                      })
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="order_details-result">
              <SectionTitle titleKey={"orderSummary"} icon={<File />} />
              {getOrderSummary()}
              <div className="order_details-note" style={{ border: 'none' }}>
                <label className="text-100">Tellimuse kommentaar</label>
                <textarea className="form-control" onChange={e => handleNoteChange(e)} placeholder="Lisa kommentaar tellimusele"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order_actions">
        <div className="row g-0">
          <div className="col-md-6">
            <Checkbox 
              checked={agreedTerms} 
              onChange={val => handleTerms(val)} 
              label={<span>{t("orderAgreement")}</span>} 
              secondLabel={<a href="/privaatsus-ja-kasutustingimused" target="_blank" className="link">{t("termsLinkText2")}</a>}
            />
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="order_actions-wrapper">
              <button disabled={!agreedTerms} onClick={() => order()} className="btn-none dark-btn">
                {t('makeOrder')}
              </button>
              <button onClick={() => saveDraft()} className="ms-3 btn-none save-draft btn-0 prim-btn">
                <Folder />
                {t('saveOrder')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div onClick={e => handleModalClick(e)} ref={modalRef} className={`modal fade ${modal ? 'show' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-wrapper search-modal">
            <div className="modal-header border-bottom">
              <h1 className="modal-title">
                {t('addProduct')}
              </h1>
              <button onClick={() => closeModal()} className="btn-none btn-0 ms-auto">
                <Close />
              </button>
            </div>
            <div className="modal-body">
              <div className="search-modal_filters">
                <Filters />
              </div>
              <div className="search-modal_results">
                <div className="search-modal_results-count">
                  <span>{t('totalProducts')}: {catalogContent.filteredContent.length}</span>
                </div>
                {
                  catalogContent.content.map(item => {
                    const props = {
                      ...item,
                      displayVat: showTaxRow,
                      addToOrder: (productID) => handleProductSelect(productID, modalProductRow)
                    }
                    return (
                      <CatalogRow key={item.productID} {...props} />
                    )
                  })
                }
              </div>
              <div className="search-modal_pagination">
                <Pagination handleClick={next => handlePaginate(next)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default CreateOrder

const OrderForm = ({ showTaxRow, orderProducts, addProduct, openSearch, changeAmount, replaceProduct, deleteRow, loading }) => {
  const { t } = useTranslation()

  const handleSelect = (product) => {
    addProduct(product)
  }

  const handleAmountChange = (e, productID) => {
    const { value } = e.target
    changeAmount(Number(value), productID)
  }

  return (
    <div className="order-form">
      <div className="order-form_header">
        <div className="row g-0">
          <div className="col-lg-3 order-form_header-left">
            <label>{t('productName')}</label>
          </div>
          <div className={"col-lg-2"}>
            <label>{t("image")}</label>
          </div>
          <div className={showTaxRow ? "col-lg-1" : "col-lg-2"}>
            <label>SKU</label>
          </div>
          <div className="col-lg-1">
            <label>{t('price')}</label>
          </div>
          {
            showTaxRow ? (
              <div className="col-lg-1">
                <label>
                  {t('priceVAT')}
                </label>
              </div>
            )
            :
            undefined
          }
          <div className="col-lg-1">
            <label>{t('amount')}</label>
          </div>
          <div className="col-lg-1">
            <label>{t('availability')}</label>
          </div>
          <div className="col-lg-1">
            <label>{t('total')}</label>
          </div>
        </div>
      </div>
      <div className="order-form_body">
        {
          loading ? (
            <Spinner />
          )
          :
          (
            orderProducts.map((product, idx) => {
              if(!product.productID){
                return (
                  <div className="row g-0 order-form_body-row" key={String(product.productID) + String(idx)}>
                    <div className="col-lg-3 order-form_body-left">
                      <div className="order-form_body-col">
                        <ProductSearch openSearch={() => openSearch(idx)} existingProduct={null} selectProduct={product => handleSelect(product)} />
                      </div>
                    </div>
                    <div className="col-lg-8" />
                    <div className="col-lg-1 delete-col">
                      <button onClick={() => deleteRow(idx)} className="btn-none btn-0">
                        <Trash />
                      </button>
                    </div>
                  </div>
                )
              }
              const stockInfo = Number(product.warehouses['1'].totalInStock)
              const inStock = stockInfo > 0
              return (
                <div className="row g-0 order-form_body-row" key={String(product.productID) + String(idx)}>
                  <div className="col-lg-3 order-form_body-left">
                    <div className="order-form_body-col">
                      <ProductSearch openSearch={() => openSearch(idx)} existingProduct={product} selectProduct={product => handleSelect(product)} />
                    </div>
                  </div>
                  <div className="col-lg-2 order-form_body-img">
                    <label className="mobile-label">{t("image")}</label>
                    <img src={product.relatedFiles && product.relatedFiles.length ? product.relatedFiles[0].fileURL : '/media/placeholder.avif'} />
                  </div>
                  <div className={showTaxRow ? "col-lg-1" : "col-lg-2"}>
                    <div className="order-form_body-meta">
                      <label className="mobile-label">SKU</label>
                      <label className="order-form_body-meta_sku">{product.code}</label>
                    </div>
                  </div>
                  <div className="col-lg-1">                 
                    <div className="order-form_body-meta">
                      <label className="mobile-label">{t("price")}</label>
                      <label>€{Number(product.priceListPrice).toFixed(2)}</label>
                    </div>
                  </div>
                  {
                    showTaxRow ? (
                      <div className="col-lg-1">   
                        <div className="order-form_body-meta">
                          <label className="mobile-label">{t("priceVAT")}</label>
                          <label>€{Number(product.priceListPriceWithVat).toFixed(2)}</label>
                        </div>
                      </div>
                    )
                    :
                    undefined
                  }
                  <div className="col-lg-1">
                    <div className="order-form_body-meta amount-meta">
                      <label className="mobile-label">{t("amount")}</label>
                      <input type='number' min="1" className="form-control" defaultValue={product.amount} onChange={e => handleAmountChange(e, product.productID)} />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="order-form_body-meta stock-meta">
                      <label className="mobile-label">{t("availability")}</label>
                      <label className={`stock-meta_info ${inStock ? 'available' : 'backorder'}`}>{inStock ? `${stockInfo} ${t('inStock')}` : t('backorder')}</label>
                    </div>
                  </div>
                  <div className="col-lg-1">   
                    <div className="order-form_body-meta">
                      <label className="mobile-label">{t("total")}</label>
                      <label>€{(product.amount * Number(showTaxRow ? product.priceListPriceWithVat : product.priceListPrice)).toFixed(2)}</label>
                    </div>
                  </div>
                  <div className="col-lg-1 delete-col">
                    <div className="order-form_body-col">
                      <button onClick={() => deleteRow(idx)} className="btn-none btn-0">
                        <Trash />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          )
        }
      </div>
      <div className="order-form_footer">
        <div className="order-form_footer-wrapper">
          <button className="btn-none btn-0 main-btn" style={{ paddingLeft: '2px' }} onClick={() => handleSelect(null)}>
            {t('addProduct')}
            <CirclePlus style={{ marginLeft: '5px' }} />
          </button>
        </div>
      </div>
    </div>
  )  
}

const ProductSearch = ({ existingProduct, openSearch }) => {

  const [value, setValue] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    if(existingProduct && existingProduct.productID){
      setValue(existingProduct.name)
    }
  }, [existingProduct])

  return (
    <div className="product-search">
      <div className="product-search_content">
        <div className="form-group" onClick={() => openSearch()}>
          <input className="form-control product-search_content-input" type="text" placeholder={t('chooseProduct')} defaultValue={value} />
          <Pencil />
        </div>
      </div>
    </div>
  )

}