import axios from 'axios'

const api = `https://tellimiskeskus.isb.ee`
//const api = 'http://localhost:4000'

const getHeaders = () => {
  //const token = localStorage.getItem('jwt-client-auth-key')
  const token = localStorage.getItem(`solider-client-side-auth-token`)
  return {
    Authorization: `JWT ${token}`
  }
}

export const postRegisterRequest = async (body, lang = 'et') => {
  const { data } = await axios.post(`${api}/auth/register?lang=${lang}`, body, { headers: getHeaders() })
  return data
}

export const adminCompanyCheck = async (registryCode) => {
  const { data } = await axios.get(`${api}/admin-api/company/check/${registryCode}`, { headers: getHeaders() })
  return data
}

export const adminDeleteCompany = async (registryCode) => {
  const { data } = await axios.delete(`${api}/admin-api/company/${registryCode}`, { headers: getHeaders() })
  return data
}

export const adminDeleteUser = async (companyID, userID) => {
  const { data } = await axios.delete(`${api}/admin-api/company/user/${userID}/${companyID}`, { headers: getHeaders() })
  return data
}

export const putAdminEditUser = async (body) => {
  const { data } = await axios.put(`${api}/admin-api/company/user`, body, { headers: getHeaders() })
  return data
}

export const postAdminUser = async (body) => {
  const { data } = await axios.post(`${api}/admin-api/company/user`, body, { headers: getHeaders() })
  return data
}

export const postAdminNewCompany = async (body) => {
  const { data } = await axios.post(`${api}/admin-api/company/create`, body, { headers: getHeaders() })
  return data
}

export const fetchSalesDocumentHTML = async (id, docNo, lang = 'et') => {
  await axios.get(`${api}/api/order/invoice/${id}?lang=${lang}`, { headers: {...getHeaders(), Accept: "application/pdf" }, responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `order_${docNo}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  })
}

export const putProductToDraftOrder = async (body, lang = 'et') => {
  const { data } = await axios.put(`${api}/api/order/update?lang=${lang}`, body, { headers: getHeaders() })
  return data
}

export const fetchNotification = async (lang = 'et') => {
  const { data } = await axios.get(`${api}/api/notifications?lang=${lang}`, { headers: getHeaders() })
  return data
}

export const postAdminNotification = async (body) => {
  const { data } = await axios.post(`${api}/admin-api/notification`, body, { headers: getHeaders() })
  return data
}

export const putAdminNotification = async (body, id) => {
  const { data } = await axios.put(`${api}/admin-api/notification/${id}`, body, { headers: getHeaders() })
  return data
}

export const fetchadminNotification = async (lang = 'et') => {
  const { data } = await axios.get(`${api}/admin-api/notifications`, { headers: getHeaders() })
  return data
}

export const adminUpdateCompany = async (body) => {
  const { data } = await axios.put(`${api}/admin-api/company/edit`, body, { headers: getHeaders() })
  return data
}

export const fetchAdminCompanyDetails = async (registryCode) => {
  const { data } = await axios.get(`${api}/admin-api/company/${registryCode}`, {headers: getHeaders()})
  return data
}

export const fetchAdminCompanies = async (page, searchQuery) => {
  let params = `pageNo=${page}`
  if(searchQuery){
    params += `&filterQuery=${searchQuery}`
  }
  const { data } = await axios.get(`${api}/admin-api/companies?${params}`, { headers: getHeaders() })
  return data
}

export const fetchUser = async (lang = 'et') => {
  const { data } = await axios.get(`${api}/auth/check?lang=${lang}`, { headers: getHeaders() })
  return data
}

export const postUserAddRequest = async (body, companyID, lang = 'et') => {
  const { data } = await axios.post(`${api}/profile/users/${companyID}?lang=${lang}`, body, { headers: getHeaders() })
  return data
}

export const putUserUpdateRequest = async (body, companyID, lang = 'et') => {
  const { data } = await axios.put(`${api}/profile/users/${companyID}?lang=${lang}`, body, { headers: getHeaders() })
  return data
}

export const deleteUserRequest = async (userID, companyID, lang = 'et') => {
  const { data } = await axios.delete(`${api}/profile/users/${companyID}?lang=${lang}&userID=${userID}`, { headers: getHeaders() })
  return data
}

export const postLoginRequest = async (body, lang = 'et') => {
  const { data } = await axios.post(`${api}/auth/login-request?lang=${lang}`, body)
  return data
}

export const postLoginCode = async (body, lang = 'et') => {
  const { data } = await axios.post(`${api}/auth/login?lang=${lang}`, body)
  return data
}

export const fetchShippingMethods = async () => {
  const { data } = await axios.get(`${api}/api/order/shipping`, { headers: getHeaders() })
  return data
}

export const postOrderDuplicate = async (body, lang = 'et') => {
  const { data } = await axios.post(`${api}/api/reorder`, body, { headers: getHeaders() })
  return data
}

export const fetchCompanyDetails = async id => {
  const { data } = await axios.get(`${api}/profile/details/${id}`, { headers: getHeaders() })
  return data
}

export const postDraftOrder = async body => {
  const { data } = await axios.post(`${api}/api/order/draft`, body, { headers: getHeaders() })
  return data
}

//dateStart = null, dateEnd = null, searchTerm = ''
export const fetchDraftOrders = async (page = 0, company, filterArray = []) => {
  let filterParams = `?pageNo=${page}&company=${company}`
  if(filterArray.length){
    filterArray.forEach(({ key, value }) => {
      filterParams += `&${key}=${value}`
    })
  }
  const { data } = await axios.get(`${api}/api/orders/draft${filterParams}`, { headers: getHeaders() })
  return data
}

export const fetchHistoricalOrders = async (page = 0, company, filterArray = []) => {
  let filterParams = `?pageNo=${page}&company=${company}`
  if(filterArray.length){
    filterArray.forEach(({ key, value }) => {
      filterParams += `&${key}=${value}`
    })
  }
  const { data } = await axios.get(`${api}/api/orders/active${filterParams}`, { headers: getHeaders() })
  return data
}

export const fetchOrderDetails = async orderID => {
  const { data } = await axios.get(`${api}/api/order/details/${orderID}`, { headers: getHeaders() })
  return data
}

export const fetchProducts = async companyID => {
  const { data } = await axios.get(`${api}/api/products/${companyID}`, { headers: getHeaders() })
  return data
}

export const fetchUpdatedProducts = async companyID => {
  const { data } = await axios.get(`${api}/api/force/products/${companyID}`, { headers: getHeaders() })
  return data
}

export const fetchDraftOrder = async (orderID, company) => {
  const { data } = await axios.get(`${api}/api/order/draft/${orderID}?company=${company}`, { headers: getHeaders() })
  return data
}

export const deleteDraft = async orderID => {
  const { data } = await axios.delete(`${api}/api/order/draft/${orderID}`, { headers: getHeaders() })
  return data
}

export const postErplyOrder = async (body) => {
  const { data } = await axios.post(`${api}/api/order`, body, { headers: getHeaders() })
  return data
}